<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>存单质检报告</p>
      </div>
    </div>
      <div class="searchList">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-input v-model="searchForm.holdCode" placeholder="请输入入库单编码"></el-input>
        </el-col>
          <el-col :span="4">
            <el-cascader
              class="width-100"
              v-model="selectCategoryOptions"
              :options="categoryOptions"
              :props="categoryProps"
              placeholder="请选择商品分类"
              clearable
            ></el-cascader>
        </el-col>
        <el-col :span="4">
          <el-input v-model="searchForm.status" placeholder="请输入样品编码"></el-input>  
        </el-col>
        <!-- <el-col :span="4">
           <el-input v-model.number="searchForm.applyHoldId" placeholder="请输入申请持仓ID"></el-input>
        </el-col> -->
        <el-col :span="4">
            <el-select class="width-100" v-model="searchForm.storageId"  placeholder="请选择质检机构">
                <el-option
                  v-for="item in storageIdOptions"
                  :key="item.institutionsId"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
        </el-col>
 
        <el-col :span="4">
          <el-button type="danger" @click="getDatas()">搜索</el-button>
          <el-button @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="tableList">
      <el-table :data="tableDatas">
        <el-table-column prop="holdCode" label="入库单编码"></el-table-column>
        <el-table-column prop="commodity" label="商品分类"></el-table-column>
        <el-table-column prop="quantity" label="数量"></el-table-column>
        <el-table-column prop="weight" label="重量">
          <template slot-scope="scope">
           {{ scope.row.weight }}{{ scope.row.weight==null?'':scope.row.unit }}
          </template>
        </el-table-column>
        <el-table-column prop="sampleCode" label="样品编码"></el-table-column>
        <!-- <el-table-column prop="applyHoldId" label="申请持仓ID"></el-table-column> -->
        <el-table-column prop="report" label="质检报告">
          <template slot-scope="scope">
            <el-image
              class="reportImg"
              :src="scope.row.report"
              :preview-src-list="[].concat(scope.row.report)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="status" label="审核状态">
          <template slot-scope="scope">
            <span>{{ scope.row.status | depositRStatusFormate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="质检机构"></el-table-column>
        <el-table-column prop="createTime" label="上传时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | DateFormate }}</span>
          </template>
        </el-table-column>
          <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button @click="showReport(scope.row)" size="mini">打印质检报告</el-button>
          </template>
        </el-table-column>
      </el-table>
       <el-dialog
       v-dialogDrag
      title=""
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="1000px"
      :before-close="handleClose">
      <div id="printContent">
        <div class="printCon">
           <h1 class="reportTitle">
            {{printDetail.commodity}}&nbsp;&nbsp;质检报告
        </h1>
       <el-image :class="isReportPrint?'isreportImg':'reportImgs'" :src="printDetail.report"></el-image>
        </div>
       
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="printReport()">打 印</el-button>
      </span>
    </el-dialog>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      searchForm: {
        storageId: null,
        status: null,
        applyHoldId: null,
        holdCode: null
      },
      dialogVisible:false,
      printDetail:{
        commodity:null,
        report:null
      },
      isReportPrint:false,
       selectCategoryOptions:[],
      categoryOptions:[],
      storageIdOptions: [],
        categoryProps: {
        label: "className",
        value: "className",
        checkStrictly: false
      },
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.getDatas();
        this.getCommodityCategory();
    this.getStorageIdOptions();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_queryQualityInspectionApplyReport.param.page =
        this.current - 1;
      protocolFwd.param_queryQualityInspectionApplyReport.param.size = this.pageSize;
      protocolFwd.param_queryQualityInspectionApplyReport.param.firmId = this.sessionInfoGetter.firmId;
      protocolFwd.param_queryQualityInspectionApplyReport.param.className = this.selectCategoryOptions[this.selectCategoryOptions.length - 1]
      protocolFwd.param_queryQualityInspectionApplyReport.param.sampleCode = this.searchForm.status
      protocolFwd.param_queryQualityInspectionApplyReport.param.holdCode = this.searchForm.holdCode
      protocolFwd.param_queryQualityInspectionApplyReport.param.applyHoldId = this.searchForm.applyHoldId
      protocolFwd.param_queryQualityInspectionApplyReport.param.name = this.searchForm.storageId
      protocolFwd.param_queryQualityInspectionApplyReport.param.sort = [ {
          direction: "DESC",
          property: "createTime"
        }];
      http
        .postFront(protocolFwd.param_queryQualityInspectionApplyReport)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            for (let i = 0; i < value.content.length; i++) {
              if (!value.content[i].holdCode) {
                value.content[i].holdCode = '---'
              }
            }
            this.tableDatas = value.content;
            this.totalPage = value.totalElements;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
     resetSearch() {
      this.searchForm = {
        storageId: null,
        status: null,
        holdId: null,
        holdCode: null
      };
      this.selectCategoryOptions = []
      this.getDatas();
    },
    getCommodityCategory() {
      http.getRes(protocolFwd.param_queryVarieties).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          let categoryArr = value
          this.categoryOptions = this.clearChildren(categoryArr);
        } else {
          this.categoryOptions = []
          this.$EL_MESSAGE(message);
        }
      });
    },
    clearChildren(Arr) {
      Arr.forEach(element => {
        if(element&&element.children){
          if(element.children.length==0){
            delete element['children']
          }else{
            this.clearChildren(element.children)
          }
        }
      });
      return Arr
    },
     getStorageIdOptions() {
      http.postFront(protocolFwd.param_findInspectionInstitutions).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.storageIdOptions = value.content;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    showReport(row){
      this.printDetail.commodity = row.commodity
      this.printDetail.report = row.report
      this.dialogVisible = true
    },
    printReport(){
      var printBox = document.getElementById("printContent");
      var newContent = printBox.innerHTML;
      var oldContent = document.body.innerHTML;
      document.body.innerHTML = newContent;
      this.isReportPrint = false
      window.print();
      window.location.reload();
      document.body.innerHTML = oldContent;
      return false;
    },
    handleClose(){
      this.dialogVisible = false
      this.printDetail = {
        commodity:null,
        report:null
      }
    },
  }
};
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.tableList {
  margin-top: 20px;
}
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th{
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox{
    line-height: 30px;
    margin-left: 20px;
  }
}
.printCon{
  text-align:center;
}
.reportTitle{
  text-align: center;
  line-height: 50px;
  font-size: 22px;
  color:black;
}
.reportTitle span{
  display: inline-block;
  width:5px;
  height: 5px;
  vertical-align: middle;
  border-radius: 50%;
  background-color:black;
}
.reportImg {
  max-width: 120px;
  max-height: 120px;
}
.isreportImg{
  width:90%;
}
.reportImgs {
  text-align:center;
  // width:100%;
  // max-width: 830px;
  // max-height: 500px;
}
.subPage .pagination {
  margin-top: 15px;
}
</style>
